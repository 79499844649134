<template>
  <div>
    <div class="item-print">
      <img
        v-if="
          bill.storeType === STORE_TYPE.DDV &&
            bill.storeId !== 101851 &&
            bill.storeId !== 101852
        "
        src="media/logos/logo-gdn-03.png"
        width="100%"
        height="10%"
        class="heading-logo"
        alt=""
      />
      <img
        v-if="bill.storeId === 101851 || bill.storeId === 101852"
        src="media/logos/vertu.jpg"
        width="100%"
        height="10%"
        class="heading-logo"
        alt=""
      />
      <img
        v-if="bill.storeType === STORE_TYPE.VDD"
        src="media/logos/vdd_hori_logo.png"
        width="100%"
        height="10%"
        class="heading-logo"
        alt=""
      />
      <!-- thông tin cửa hàng-->
      <div class="heading">
        <!-- tên chi nhánh -->
        <!-- <p class="bill-content">{{ bill.storeName }}</p> -->
        <!-- địa chỉ chi nhánh -->
        <!-- <p class="bill-content">
          {{ bill.storeAddress }}
        </p> -->
        <!-- số hotline -->
        <p class="bill-content">Hotline: {{ getPhoneNumberByStoreType }}</p>
        <!-- kết thúc thông tin cửa hàng-->
      </div>
      <h5
        class="text-bold text-center"
        style="font-size: 26px; margin-left: 30px"
      >
        PHIẾU BÁN HÀNG
      </h5>
      <!-- thông tin khách hàng -->
      <div class="customer font-size-print text-500">
        <div>
          <span class="text-title">Tên</span>
          <span style="margin-left: 4px;">{{ bill.customerName }}</span>
        </div>
        <div>
          <span class="text-title">Điện thoại</span>
          <span style="margin-left: 4px;">{{ bill.customerPhone }}</span>
        </div>
        <div style="display: flex; align-items: baseline;">
          <span class="text-title">Địa chỉ</span>
          <span style="margin-left: 8px; line-height: 1.375;">{{
            bill.customerAddress
          }}</span>
        </div>
        <div>
          <span class="text-title">Số ID</span>
          <span class="text-bold-600" style="margin-left: 4px;">{{
            bill.billNumber
          }}</span>
        </div>
        <div>
          <span class="text-title">Ngày tạo</span>
          <span class="text-bold-600" style="margin-left: 4px;">{{
            bill.createdAt
          }}</span>
        </div>
      </div>
      <!-- sản phẩm -->
      <div class="product-section">
        <table class="product-table">
          <thead>
            <tr>
              <th class="text-center">
                <span class="text-bold-600 font-size-print">SL</span>
              </th>
              <th class="text-center">
                <span class="text-bold-600 font-size-print">Đơn giá</span>
              </th>
              <th class="text-center">
                <span class="text-bold-600 font-size-print">Chiết khấu</span>
              </th>
              <th class="text-right">
                <span class="text-bold-600 font-size-print">Thành tiền</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <div
              class="list-product"
              v-for="(item, index) in bill.listBillItem"
              :key="index"
            >
              <tr>
                <td colspan="4">
                  <span
                    class="label-color font-size-print"
                    style="margin-right: 4px;"
                  >
                    <i
                      style="color: rgb(24, 28, 50)"
                      class="fas fa-gift ml-4"
                      v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                    ></i>
                  </span>
                  <span class="bill-content font-size-print">{{
                    item.name
                  }}</span>
                </td>
              </tr>
              <tr v-if="item.imeiCode">
                <td colspan="4">
                  <span class="font-size-print" style="font-style: italic;">
                    IMEI: {{ item.imeiCode }}
                  </span>
                </td>
              </tr>
              <tr style="border-bottom: 0.5px dashed black;">
                <td class="text-center">
                  <span class="bill-content font-size-print">{{
                    item.quantity
                  }}</span>
                </td>
                <td class="text-center">
                  <span
                    v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                    >0</span
                  >
                  <span v-else class="bill-content font-size-print">{{
                    convertPrice(item.price)
                  }}</span>
                </td>
                <td class="text-center">
                  <span
                    v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                    >0</span
                  >
                  <span v-else class="bill-content font-size-print">{{
                    convertPrice(item.discountAmountValue)
                  }}</span>
                </td>
                <td class="text-right">
                  <span
                    v-if="item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS"
                    >0</span
                  >
                  <span v-else class="bill-content font-size-print">{{
                    convertPrice(item.totalPrice)
                  }}</span>
                </td>
              </tr>
            </div>
          </tbody>
          <tfoot>
            <tr>
              <td class="font-size-print text-left" colspan="3">
                <span>Tổng tiền (sản phẩm):</span>
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.sumUnitAmount) }}
              </td>
            </tr>
            <tr>
              <td colspan="3" class="text-left">
                <span class="font-size-print text-right">Tổng chiết khấu:</span
                ><br />
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(totalDiscountAmount || 0) }}
              </td>
            </tr>
            <tr>
              <td class="font-size-print text-left" colspan="3">
                <span>Tiền đã cọc:</span>
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.depositAmount) }}
              </td>
            </tr>
            <tr v-if="getTotalTradein > 0">
              <td class="font-size-print text-left" colspan="3">
                <span>Tiền thu cũ:</span><br />
                <strong class="font-size-print" style="font-size:12px"
                  >(IMEI: {{ bill.tradeIn.productIMEI }})</strong
                >
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(getTotalTradein) }}
              </td>
            </tr>
            <tr>
              <td class="font-size-print text-left" colspan="3">
                <span>Phí ship:</span>
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.shipFee) }}
              </td>
            </tr>
            <tr>
              <td class="font-size-print text-left" colspan="3">
                <span></span>Tổng tiền (sau CK):
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(totalAmountAfterDiscount || 0) }}
              </td>
            </tr>
            <tr>
              <td class="font-size-print text-left" colspan="3">
                <span></span>Tiêu điểm:
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.pointUse) }}
              </td>
            </tr>
            <tr v-if="bill.conversionFee">
              <td class="font-size-print text-left" colspan="3">
                <span></span>Phí chuyển đổi:
              </td>
              <td class="text-right font-size-print text-bold-600">
                {{ convertPrice(bill.conversionFee) }}
              </td>
            </tr>
            <tr style="font-size: 18px;">
              <td colspan="3" class="text-left text-bold-600">
                <span style="font-style: italic"></span> Tổng tiền phải trả:
              </td>
              <td class="text-right text-bold-600">
                {{ convertPrice(bill.totalPay) }}
              </td>
            </tr>
            <tr class="font-size-print">
              <td class="text-left" colspan="4">
                <span>Tổng tiền bằng chữ: {{ bill.textAmount }}</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <!-- tích điểm -->
      <div class="loyalty font-size-print text-500">
        <div v-if="bill.addPoint > 0">
          <span class="text-title">Số điểm được tích</span>
          <span>{{ bill.addPoint }}</span>
        </div>
        <div>
          <span class="text-title">Điểm tích lũy còn lại</span>
          <span>{{ bill.customerPoint }}</span>
        </div>
        <div>
          <span class="text-title">Tiêu điểm tích lũy</span>
          <span>{{ bill.subtractPoint }} điểm</span>
        </div>
        <div>
          <span class="text-title">Tiền đã cọc</span>
          <span>{{ convertPrice(bill.depositAmount) }}</span>
        </div>
        <div v-if="bill.transferFromOrder > 0">
          <span>Tiền đã chuyển khoản</span>
          <span>{{ convertPrice(bill.transferFromOrder) }}</span>
        </div>
        <div v-if="bill.moneyCredit > 0">
          <span>Tiền đã quẹt thẻ</span>
          <span>{{ convertPrice(bill.moneyCredit) }}</span>
        </div>
        <div v-if="bill.moneyInstallment > 0">
          <span>Tiền đã trả góp:</span>
          <span>{{ convertPrice(bill.moneyInstallment) }}</span>
        </div>
        <div>
          <span class="text-title">CK trên hóa đơn</span>
          <span>{{ convertPrice(bill.discountValue) }}</span>
        </div>
        <div v-if="bill.customerShipFee > 0">
          <span>Phí ship</span>
          <span>{{ convertPrice(bill.customerShipFee) }}</span>
        </div>
      </div>
      <!-- Thông tin bảo hành -->
      <hr style="margin-top: 10px" />
      <div class="warranty font-size-print text-500">
        <div style="display: flex; justify-content: space-between">
          <span>Bảo hành: 1800.6729</span><span>Khiếu nại: 1800.6306</span>
        </div>
      </div>
      <hr style="margin-top: 10px" />
      <!-- thông tin người bán -->
      <div
        class="loyalty font-size-print text-500"
        style="margin-bottom: 10px;"
      >
        <div>
          <span class="text-title">Tư vấn bán hàng</span>
          <span>{{ bill.saleName }}</span>
        </div>
        <div>
          <span class="text-title">Thu ngân</span>
          <span>{{ bill.cashierName }}</span>
        </div>
        <div>
          <span class="text-title">Địa chỉ cửa hàng</span>
          <span>{{ bill.storeAddress }}</span>
        </div>
      </div>
      <hr />
      <div style="display: inline-flex">
        <qr-code :text="linkViewWarranty" :size="100" error-level="L"></qr-code>
        <b-col>
          <p class="text-download-app label-color">
            Quét mã để tải app và xem chi tiết sản phẩm, bảo hành.
          </p>
        </b-col>
      </div>
      <hr style="margin-top: 20px !important" />
      <div style="display: inline-flex">
        <qr-code :text="linkHR" :size="100" error-level="L"></qr-code>
        <b-col>
          <p class="text-download-app label-color">
            Quét mã QR để tham khảo các cơ hội việc làm hấp dẫn tại Di Động
            Việt.
          </p>
        </b-col>
      </div>
      <hr style="margin-top: 20px !important" />
      <div class="text-align-center">
        <p class="text-styles">
          Phiếu tính tiền chỉ có giá trị xuất hóa đơn GTGT trong vòng 24h tính từ ngày mua hàng.<br />
          Quý khách vui lòng liên hệ Cửa hàng để cung cấp thông tin xuất hóa đơn GTGT.
        </p>
        <p style="font-style: italic; font-size: 15px;">
          Cảm ơn quý khách và hẹn gặp lại!
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { convertPrice } from '@/utils/common';
import { BILL_ITEM_TYPE, STORE_TYPE } from '@/utils/enum';
import VueQRCodeComponent from 'vue-qrcode-component';

export default {
  props: ['bill', 'productUsages'],
  data() {
    return {
      STORE_TYPE,
      BILL_ITEM_TYPE,
      linkHR: 'https://didongviet.vn/dchannel/muc-tuyen-dung/',
      linkDownloadApp: 'https://ddv.onelink.me/uPCc/s8wbgntz?af_qr=true',
      linkViewWarranty: 'https://didongviet.vn/',
    };
  },
  methods: {
    convertPrice,
  },
  created() {
    const billNumber = this.$route.query.id;
    this.linkViewWarranty = `https://ddvloyalty.page.link/?link=https://ddvloyalty.page.link/billInfo?billNumber=${billNumber}&apn=com.didongviet.loyalty`;
  },
  computed: {
    getPhoneNumberByStoreType() {
      return this.bill.storeHotline ? this.bill.storeHotline : '1800 6018';
    },
    getTotalTradein() {
      if (Object.keys(this.bill.tradeIn).length) {
        return this.bill.tradeIn.finalBuyingPrice || 0;
      }
      return 0;
    },
    totalDiscountAmount() {
      return this.bill.sumTotalDiscount + this.bill.discountAmount;
    },
    totalAmountAfterDiscount() {
      return (
        this.bill.sumUnitAmount -
        (this.bill.sumTotalDiscount + this.bill.discountAmount)
      );
    },
  },
  components: {
    'qr-code': VueQRCodeComponent,
  },
};
</script>
<style lang="css" scoped>
/* ================ Common ================ */
.tab {
  position: absolute;
  left: 10em;
}

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.customer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.customer .text-title {
  min-width: 80px;
  display: inline-block;
  position: relative;
}

.customer .text-title::after {
  content: ':';
  position: absolute;
  right: -4px;
}

.text-bold-600 {
  font-weight: 600;
}

.list-product {
  display: contents;
}
.heading-logo {
  object-fit: contain;
}
.bill-content {
  font-weight: 500;
  line-height: 1.6;
  color: #000000;
  margin-bottom: 0;
}
tr th {
  color: #000000;
  font-weight: 500;
}

tr td {
  color: #000000;
  font-weight: 500;
}

.heading {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
}

.loyalty {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  gap: 1rem;
}

.loyalty .text-title {
  min-width: 160px;
  display: inline-block;
  position: relative;
}

.loyalty .text-title::after {
  content: ':';
  position: absolute;
  right: 0;
}

hr {
  border: none;
  border-top: 2px dotted black;
  color: #fff;
  background-color: #fff;
  height: 2px;
}
hr.dotted {
  border: none;
  border-top: 1px dotted cadetblue;
  color: #fff;
  background-color: #fff;
  height: 1px;
}

.product-table {
  margin: 10px 0;
  width: 100%;
}

.product-table thead tr {
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  line-height: 30px;
}

.product-table tfoot {
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  line-height: 24px;
}

.product-table tbody tr {
  line-height: 24px;
}

.list-product tr:last-child {
  border-bottom: 1px dashed black;
}

.text-bold {
  color: black;
  font-weight: 600;
}

.text-500 {
  color: black;
  font-weight: 500;
}

.text-align-center {
  text-align: center;
}

.text-styles {
  color: black;
  font-size: 12.5px;
  font-weight: 500;
}
.content-info {
  color: black;
  font-weight: 450;
}
.font-size-print {
  font-size: 14px;
}
.content-product {
  line-height: 1.375;
  white-space: pre-wrap;
  font-size: 13px;
  margin-top: 4px;
}
.item-print {
  width: 97mm !important;
  margin-left: 30px !important;
}
.text-download-app {
  color: black;
  font-weight: 500;
  margin-top: 4px;
  line-height: 1.3;
}
</style>
